<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center" v-if="error">
        <div class="alert g">
          <h3>
            {{ error }}
          </h3>
        </div>
      </div>
      <div class="w-100 text-center" v-if="!error">
        <h4>
          <span v-if="loading">
            <img
              :src="require('@/assets/images/loading.svg')"
              style="width: 80px"
              alt=""
            />
            <br />
            جاري التحميل...
          </span>
          <span v-if="!loading">
            {{ message }}
          </span>
        </h4>
        <br />
        <div v-if="!loading">
          <div class="form-group text-left">
            <h5 for="">الإسم</h5>
            <input
              type="text"
              v-model="name"
              class="form-control"
              placeholder="اكتب اسمك هنا..."
            />
          </div>
          <div class="form-group text-left">
            <h5 for="">الجوال</h5>
            <input
              type="number"
              v-model="phone" @keyup="checkPhone()"
              class="form-control"
              placeholder="اكتب رقمك هنا..."
            />
          </div>
          <span id="validate" class="text-center text-danger" v-html="validate"></span>
          <div class="col-12 text-center" v-if="!validate">
            <button class="btn btn-primary" @click="send()">
              إرسال الآن <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
        <br />
      </div>
      <br />

      <a href="https://smart-whats.com" class="text-center">
        <h6 class="brand-text text-primary ml-1 g" style="">
          <img
            :src="require('@/assets/images/logo/logo.png')"
            style="width: 24px"
            alt=""
          />
          smart-whats.com | خدمات إرسال الرسائل للواتسآب <br />
          <u>اضغط هنا لمعرفة المزيد عن الخدمة</u>
        </h6>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
      loading: true,
      message: "برجاء كتابة اسمك ورقم الجوال",
      error: false,
      name: null,
      phone: null,
      validate: null,
      country: null
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    checkPhone(){
      var g = this;
      if(g.phone){
        if(g.country == 'eg'){
        if(g.phone.substring(0,1) != '0'){
          g.validate = "يجب ان يبدأ الرقم بصفر وان يكون باللغة الانجليزية"
        }else{
          g.validate = null
        }
      }
      if(g.country == 'sa'){
        if(g.phone?.length > 12){
          g.validate = "يجب ان يكون الرقم 12 رقم كحد اقصى"
        }else{
          if(g.phone.substring(0,1) != '0' && g.phone.substring(0,3) != '966'){
            g.validate = "يجب ان يبدأ الرقم بصفر وان يكون باللغة الانجليزية او يبدأ ب966"
          }else{
            g.validate = null
          }
        }
      }
      }
    },
    send() {
      var g = this;
      if(g.phone && g.phone?.trim() != ""){
        g.loading = true;
        if(g.country == "sa"){
          if (g.phone.substr(0, 2) == "05") {
              g.phone = "966" + g.phone.substr(1, 50);
          } else if (g.phone.substr(0, 5) == "00966") {
              g.phone = g.phone.substr(2, 50);
          } else if (g.phone.substr(0, 4) == "0966") {
              g.phone = "9" + g.phone.substr(2, 50);
          } else if (g.substr(0, 1) == "5") {
              g.phone = "966" + g.phone;
          }
        }
        if(g.country == "eg"){
          g.phone = g.phone.replaceAll("-", '');
          g.phone = g.phone.replaceAll(" ", '');
          if (g.phone.substr(0, 1) == '1') {
              g.phone = "20" + g.phone;
          } else if (g.phone.substr(0, 2) == '01') {
              g.phone = "2" + g.phone;
          }
        }
        $.post(api + "/user/contacts/rg/send", {
          id: window.location.href.split("rg/")[1],
          name: g.name,
          phone: g.phone,
        })
          .then(function (r) {
            g.loading = false;
            g.error = r.response;
          })
          .catch(function () {
            g.error = "حدث خطأ في الاتصال";
          });
      }else{
        alert("رقم الجوال مطلوب")
      }
    },
  },
  created() {
    var g = this;
    $.post(api + "/user/contacts/rg", {
      id: window.location.href.split("rg/")[1],
    })
      .then(function (r) {
        g.loading = false;
        if (r.status == 100) {
          g.message = r.response?.message ?? "برجاء كتابة اسمك ورقم الجوال";
          g.country = r.response?.country
        } else {
          g.error = r.response;
        }
      })
      .catch(function () {
        g.error = "حدث خطأ في الاتصال";
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.chatbtna_a{
  display: none !important;
}
</style>
